import React from "react";
import {
    Link
} from "react-router-dom";

function Footer() {
    const firstYear = 2009;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <div className="jumbotron jumbotron-fluid footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                            <p className="h4 footer-title">
                                Meer van ons
                            </p>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a
                                        href="https://smithsolar.nl/"
                                        className="nav-link footer-link"
                                    >
                                        Smith Solar B.V.
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://gridsense.nl/"
                                        className="nav-link footer-link"
                                    >
                                        GridSense
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/"
                                        className="nav-link footer-link"
                                    >
                                        Werken bij Smith
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://smithtuinbouwtechniek.nl/"
                                        className="nav-link footer-link"
                                    >
                                        Smith Tuinbouwtechniek B.V.
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid footer-secondary">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row text-center">
                        <div className="flex-grow-1 text-md-left">
                            Copyright &copy; {yearString} J.K. Smith Holding B.V. All rights reserved.
                        </div>
                        <div className="text-md-right mt-1 mt-md-0">
                            A website by{" "}
                            <a
                                href="https://srproductions.nl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                SR Productions
                            </a>.
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);
