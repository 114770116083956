import React, {
    useContext
} from "react";
import Helmet from "react-helmet";
import {
    Link
} from "react-router-dom";

import JobsContext from "../context/JobsContext";

function Home() {
    const jobsContext = useContext(JobsContext);

    return (
        <React.Fragment>
            <Helmet>
                <title>Werken bij Smith - Smith Solar en Smith Tuinbouwtechniek</title>
                <meta name="description" content="Wij zoeken collega's bij Smith Solar en Smith Tuinbouwtechniek!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Werken bij Smith</h1>
                    <p>Werken bij Smith Solar en Smith Tuinbouwtechniek</p>
                </div>
            </div>

            <div className="container">
                <div className="text-center" style={{ transform: "rotate(-7deg)", margin: "40px 0"}}>
                    <p className="slogan" style={{ fontSize: "40px" }}>Wij zoeken collega's!</p>
                </div>

                <h1>Vacatures</h1>
                <p className="text-muted">
                    <b>Hieronder staat een lijst met de vacatures die nu beschikbaar zijn!</b>
                </p>

                { jobsContext.accountManagerZonnepanelen && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Account Manager Zonnepanelen</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Dagelijks adviseer je klanten over de mogelijkheden om energiebesparingen te realiseren.
                                Je verdiept je vooraf goed in de woonsituatie en de wensen van de klant en beoordeelt
                                met welke toepassing je het beste resultaat kunt bereiken. Je overlegt met collega’s van
                                werkvoorbereiding voordat je de offerte de deur uit doet. Je neemt vervolgens de
                                aanbieding door met de klant en past deze indien nodig aan...
                            </p>
                            <Link to="/vacature/account-manager-zonnepanelen" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.administratiefMedewerkerInkoop && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Administratief medewerker inkoop</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 32-40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ben jij gestructureerd en hou je van een puzzel als het gaat om uitzoeken van materialen
                                en voorraden beheren? Ga dan aan de slag als administratief medewerker inkoop bij Smith
                                Solar B.V.
                            </p>
                            <Link to="/vacature/administratief-medewerker-inkoop" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.afterSalesCoordinator && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">After Sales Coördinator</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ben jij een problemenoplosser pur sang en heb je affiniteit met elektrotechniek? En wil 
                                jij graag aan de slag binnen een informeel bedrijf in een zelfstandige rol? Dan zijn wij 
                                op zoek naar jou!
                            </p>
                            <Link to="/vacature/after-sales-coordinator" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.commercieelMedewerkerBinnendienst && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Commercieël Medewerker Binnendienst</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 32-40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ter versterking van ons team op kantoor zijn wij op zoek naar een Commercieël Medewerker Binnendienst.
                                Kan jij onze potentiële klanten overtuigen van ons product zodat ze daadwerkelijk klant
                                worden? Ben jij 32-40 uur beschikbaar? Dan zijn wij op zoek naar jou!
                            </p>
                            <Link to="/vacature/commercieel-medewerker-binnendienst" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.verkoopBinnendienst && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Commerciële tijger - Medewerker Verkoop Binnendienst</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ter versterking van ons team op kantoor zijn wij op zoek naar een commerciële tijger met
                                haar op de tanden. Kan jij op z’n Westlands onze potentiële klanten overtuigen van ons
                                product zodat ze daadwerkelijk klant worden? Dan zijn wij op zoek naar jou!
                            </p>
                            <Link to="/vacature/verkoop-binnendienst" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.verkoopBinnendienst24uur && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Commerciële tijger - Medewerker Verkoop Binnendienst</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van minimaal 24 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ter versterking van ons team op kantoor zijn wij op zoek naar een commerciële tijger met
                                haar op de tanden. Kan jij op z’n Westlands onze potentiële klanten overtuigen van ons
                                product zodat ze daadwerkelijk klant worden? Dan zijn wij op zoek naar jou!
                            </p>
                            <Link to="/vacature/verkoop-binnendienst-24-uur" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.elektromonteur && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Elektromonteur - Aanpakker zonder hoogtevrees</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> VMBO
                                </b>
                            </p>
                            <p className="card-text">
                                In deze functie ligt de nadruk op de installatie van zonnepanelen. Dit zowel bij
                                particulieren als bij zakelijke klanten. Het is aan jou om de reeds voorbereide
                                werkzaamheden te vertalen naar goed werkende systemen...
                            </p>
                            <Link to="/vacature/elektromonteur" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.hulpMonteurElektrotechniek && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Hulp monteur Elektrotechniek</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> VMBO
                                </b>
                            </p>
                            <p className="card-text">
                                Als <b className="text-highlight">Assistent Elektromonteur</b> heb je een belangrijke,
                                ondersteunende taak. Samen met de zelfstandig werkende Elektromonteurs verzorg je de
                                volledige installatie van de elektrotechnische installaties die door ons worden ontwikkeld.
                                Het is aan jou om de door ons ontwikkelde oplossingen te vertalen naar goed werkende
                                systemen. Omdat je afwisselend werkt met zwakstroom, sterkstroom, klimaattechniek en
                                beveiligingstechniek, doe je snel veel ervaring op: afwisseling is dus gegarandeerd!
                            </p>
                            <Link to="/vacature/hulp-monteur-elektrotechniek" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.magazijnMedewerker && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Geordende Magazijnmedewerker</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                In deze functie ben jij de baas in het magazijn! Je gaat lekker zelfstandig aan het werk
                                voor 40 uur per week. Zoek jij dus een baan vol afwisseling waar je lekker praktisch
                                bezig bent dan hoef je niet verder te zoeken. Lees snel verder!
                            </p>
                            <Link to="/vacature/magazijnmedewerker" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.werkvoorbereiderElektromonteur && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Werkvoorbereider / Elektromonteur die door wil groeien</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Als werkvoorbereider organiseer je alles voor je collega’s op de werkvloer ter
                                voorbereiding van alle installatietechnische projecten: werktekeningen, uittrekstaten,
                                materiaal en materieel etc. Zowel voor tuinbouwtechnische projecten als de zonnepanelen.
                                Monteurs worden blij van je werkinstructies en weten je te vinden bij een technische
                                vraag. Daarnaast zit je bovenop deadlines en budgetten.
                            </p>
                            <Link to="/vacature/werkvoorbereider-elektromonteur" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.werkvoorbereiderPlanner && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Werkvoorbereider / Planner</h5>
                            <p className="card-text">
                                <b className="text-solar ml-2 mr-2">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw ml-2 mr-2">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ben jij gestructureerd en hou je van een puzzel als het gaat om het uitzoeken van
                                materialen en voorraden beheren? Ga dan aan de slag binnen deze veelzijdige rol als
                                Werkvoorbereider / Planner bij Smith Solar B.V.
                            </p>
                            <Link to="/vacature/werkvoorbereider-planner" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { jobsContext.tekenaarWerkvoorbereiderInstallatietechniek && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Tekenaar / Werkvoorbereider Installatietechniek</h5>
                            <p className="card-text">
                                <b className="text-solar mr-4">
                                    <i className="fas fa-clock"/> Werkweek van 40 uur
                                </b>
                                <b className="text-tuinbouw mr-4">
                                    <i className="fas fa-graduation-cap"/> MBO
                                </b>
                            </p>
                            <p className="card-text">
                                Ben jij een Elektromonteur met ambitie, die door wil groeien naar onze vacature van
                                Werkvoorbereider/Tekenaar in een uitdagende omgeving? Wil je graag aan de slag binnen een
                                informeel bedrijf in een zelfstandige rol? Dan zijn wij op zoek naar jou!
                            </p>
                            <Link to="/vacature/tekenaar-werkvoorbereider-installatietechniek" className="btn btn-primary">Meer lezen</Link>
                        </div>
                    </div>
                )}

                { Object.values(jobsContext).find((value) => value) === undefined && (
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Open sollicitatie</h5>
                            <p className="card-text">
                                Momenteel hebben wij geen vacatures beschikbaar. U kunt altijd een open sollicitatie
                                sturen naar <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a>.
                            </p>
                        </div>
                    </div>
                )}

            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);
