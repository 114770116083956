import React from "react";
import Helmet from "react-helmet";

function AfterSalesCoordinator() {
    return (
        <React.Fragment>
            <Helmet>
                <title>After Sales Coördinator - Vacature - Werken bij Smith</title>
                <meta name="description" content="Ben jij een problemenoplosser pur sang en heb je affiniteit met elektrotechniek? En wil jij graag aan de slag binnen een informeel bedrijf in een zelfstandige rol? Dan zijn wij op zoek naar jou!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>After Sales Coördinator</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <h2>Knallen</h2>
                <p>
                    Dat we samen vooruit willen bij Smith mag duidelijk zijn. Ooit zijn we gestart met alleen Smith Tuinbouwtechniek B.V., maar inmiddels is Smith Solar B.V. ons paradepaardje.
                </p>
                <p>
                    Onze medewerkers zijn gewend om soms een blauwe en soms een oranje pet op te hebben. Onze organisatie bestaat uit een klein en hecht team, waarbij collegialiteit vanzelfsprekend is en we elke dag keihard knallen voor onze klanten.
                </p>
                <p>
                    Momenteel ligt het zwaartepunt van onze werkzaamheden bij Smith Solar B.V. Als After Sales Coördinator bij Smith ben jij het aanspreekpunt voor alle problemen die binnen komen.
                </p>

                <h2>Puzzlefreak</h2>
                <p>
                    Jij bent de verantwoordelijke voor het aannemen, behandelen en (laten) oplossen van alle problemen die gemeld worden. Je beoordeelt wat voor soort probleem het is, bij wie het gemeld moet worden (onze leveranciers bijvoorbeeld) en of wij onverhoopt verantwoordelijk zijn.
                </p>
                <p>
                    Je bent geen stresskip en ziet de problemen die binnen komen als puzzles die opgelost moeten worden. Moet je zelf even langs op locatie, dan doe je dat, of je plant één van de storingsmonteurs in om de storing te gaan oplossen.
                </p>

                <h2>Wat je in huis moet hebben</h2>
                <p>
                    Deze rol vraagt een zelfstandige houding, een combinatie van bepaalde competenties maar ook verstand van zaken. Deze drie samen zorgen ervoor dat jij de juiste match bent voor de vacature.
                </p>
                <ul>
                    <li>
                        Minimaal MBO werk- en denkniveau.
                    </li>
                    <li>
                        Minimaal één jaar werkervaring in een soortgelijke functie.
                    </li>
                    <li>
                        Je hebt sterk ontwikkelde communicatieve vaardigheden, bent ambitieus, klantvriendelijk, commercieel én je denkt in oplossingen.
                    </li>
                    <li>
                        Je beheerst de Nederlandse taal zowel mondeling als schriftelijk goed.
                    </li>
                    <li>
                        Je bent een echte teamplayer.
                    </li>
                    <li>
                        Bereidheid tot het volgen van met de functie verband houdende cursussen.
                    </li>
                    <li>
                        Geen 9 tot 5 mentaliteit.
                    </li>
                </ul>

                <h2>Tof bedrijf</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf, een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland. Tegelijkertijd vind je alle gekheid hier alleen op stokjes en wordt er keihard gewerkt. Onze organisatie groeit snel en we bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Ben jij een problemenoplosser pur sang en heb je affiniteit met elektrotechniek? En wil jij graag aan de slag binnen een informeel bedrijf in een zelfstandige rol? Dan zijn wij op zoek naar jou!
                </p>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(AfterSalesCoordinator);
