import React from "react";
import Helmet from "react-helmet";
import {
    Link
} from "react-router-dom";

function Page404() {
    return (
        <React.Fragment>
            <Helmet>
                <title>404 Not Found - Werken bij Smith</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>404 Not Found</h1>
                </div>
            </div>

            <div className="container text-center">
                <Link to="/" className="btn btn-primary btn-lg">Terug naar home</Link>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Page404);
