import React from "react";
import Helmet from "react-helmet";

function CommercieelMedewerkerBinnendienst() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Commercieël Medewerker Binnendienst - Vacature - Werken bij Smith</title>
                <meta name="description" content="Kan jij onze potentiële klanten overtuigen van ons product zodat ze daadwerkelijk klant worden? Ben jij 32-40 uur beschikbaar? Dan zijn wij op zoek naar jou!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Commercieël Medewerker Binnendienst</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 32-40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    Ter versterking van ons team op kantoor zijn wij op zoek naar een <b>Commercieël Medewerker Binnendienst</b>.
                    Kan jij onze potentiële klanten overtuigen van ons product zodat ze daadwerkelijk klant worden?
                    Ben jij 32-40 uur beschikbaar? Dan zijn wij op zoek naar jou!
                </p>

                <h2>Wat ga je doen</h2>
                <ul>
                    <li>
                        Je bent het eerste aanspreekpunt binnen Smith, klantgerichtheid staat bij jou bovenaan.
                    </li>
                    <li>
                        Samen met het sales team zorg je dat binnengekomen leads voorbereid en benaderd worden.
                    </li>
                    <li>
                        Eenvoudige daken bereid je zelf voor, als het wat ingewikkelder wordt plan je een afspraak in zodat je buitendienst collega’s van Sales daar langs kunnen gaan.
                    </li>
                    <li>
                        Voorbereiden en maken van offertes naar aanleiding van de wensen van de kanten.
                    </li>
                    <li>
                        Klantcontact onderhouden van begin tot eind met als doel: “een tevreden klant”.
                    </li>
                </ul>

                <h2>Wie ben je</h2>
                <ul>
                    <li>
                        De verkoopsignalen van potentiële klanten voel je feilloos aan en je voelt je ook nog eens verbonden met het Westland.
                    </li>
                    <li>
                        Je werkt nauw samen met het gehele sales team en je ondersteunt bij alle commercieel gerelateerde werkzaamheden.
                    </li>
                    <li>
                        Door jouw inzet kan de rest van het Sales team optimaal verkoop, maar het liefste streef je ze voorbij met het aantal leads dat je verkocht krijgt.
                    </li>
                    <li>
                        De diverse commerciële taken zorgen ervoor dat je een hele afwisselende baan hebt waarbij je te maken krijgt met diverse disciplines en afdelingen binnen de organisatie.
                    </li>
                </ul>

                <h2>All we ask</h2>
                <ul>
                    <li>
                        MBO werk- en denkniveau
                    </li>
                    <li>
                        Gepokt en gemazeld in Excel
                    </li>
                    <li>
                        Commercieel gedreven
                    </li>
                    <li>
                        Nederlands sprekend
                    </li>
                </ul>

                <h2>Wat wij bieden</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt, bij Smith gaat dit als vanzelf:
                </p>
                <ul>
                    <li>
                        Marktconform salaris
                    </li>
                    <li>
                        25 vakantiedagen op jaarbasis
                    </li>
                    <li>
                        Betrokken familiebedrijf waar iedereen elkaar kent
                    </li>
                    <li>
                        Mogelijkheden om je talenten te ontplooien.
                    </li>
                    <li>
                        Gezellige vrijdagmiddag borrel aan onze eigen hipsterbar
                    </li>
                    <li>
                        Nuchtere Westlandse mentaliteit
                    </li>
                </ul>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(CommercieelMedewerkerBinnendienst);
