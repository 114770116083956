import React from "react";
import Helmet from "react-helmet";

function AccountManagerZonnepanelen() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Account Manager Zonnepanelen - Vacature - Werken bij Smith</title>
                <meta name="description" content="Als Assistent Elektromonteur heb je een belangrijke, ondersteunende taak. Samen met de zelfstandig werkende Elektromonteurs verzorg je de volledige installatie van de elektrotechnische installaties die door ons worden ontwikkeld. Het is aan jou om de door ons ontwikkelde oplossingen te vertalen naar goed werkende systemen. Omdat je afwisselend werkt met zwakstroom, sterkstroom, klimaattechniek en beveiligingstechniek, doe je snel veel ervaring op: afwisseling is dus gegarandeerd!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Account Manager Zonnepanelen</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> VMBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <h2>Jouw baan</h2>
                <p>
                    Dagelijks adviseer je klanten over de mogelijkheden om energiebesparingen te realiseren. Je verdiept
                    je vooraf goed in de woonsituatie en de wensen van de klant en beoordeelt met welke toepassing je
                    het beste resultaat kunt bereiken. Je overlegt met collega’s van werkvoorbereiding voordat je de
                    offerte de deur uit doet. Je neemt vervolgens de aanbieding door met de klant en past deze indien
                    nodig aan.
                </p>
                <p>
                    Als de klant ja zegt ga je ervoor zorgen dat alles tot in de puntjes wordt voorbereid. Ook als de
                    zonnepanelen door je collega installateurs uitgevoerd wordt houd je de vinger aan de pols en lever
                    je de nodige nazorg.
                </p>
                <p>
                    Nu wordt niet iedereen niet zomaar klant. Je zult ook zelf aan de bak moeten om ze binnen te halen.
                    Je volgt leads op uit het netwerk van <b className="text-solar">Smith Solar B.V.</b> maar pakt ook
                    de telefoon om nieuwe contacten te leggen. Daarnaast onderhoud je de relatie met bestaande klanten.
                </p>

                <h2>Jouw profiel</h2>
                <p>
                    Je toont eigen initiatief en eigen verantwoordelijk en krijgt hiervoor ruimte om je ontwikkelen en
                    vrijheid in je werk voor terug. Je hebt al de nodige ervaring in de verkoop en in ieder geval
                    interesse in techniek. Je vindt het een uitdaging om iedere keer weer een klant tevreden te maken,
                    maar natuurlijk ook je eigen targets te behalen. Je kunt goed luisteren en weet klanten te
                    overtuigen met je enthousiasme en kennis. Je houdt niet van stil zitten en zoekt uitdagingen en wil
                    je blijven ontwikkelen. Je beschikt al over afgeronde mbo-opleiding.
                </p>

                <h2>Je collega's</h2>
                <p>
                    <b className="text-solar">Smith Solar B.V.</b> is specialist op het gebied van energiebesparingen
                    door solarsystemen, zoals zonnepanelen en zonneboilers. We doen kleine en grote projecten bij
                    particulieren en bedrijven met een eigen team van installateurs. Op ons gezellige kantoor werk je
                    met collega’s van werkvoorbereiding, sales, administratie en logistiek. Samen dragen ze een steentje
                    bij aan het succes van het bedrijf.
                </p>

                <h2>Het aanbod</h2>
                <p>
                    Over salaris valt te praten. We bieden je daarnaast alle ruimte en kansen om je zelf te ontwikkelen.
                    Over vervoer hoef je niet in te zitten want je krijgt een eigen bedrijfsauto. We spijkeren, indien
                    nodig, je technische kennis bij via opleidingen die we voor je verzorgen. Je werkt samen in een
                    hecht team van experts, techneuten maar vooral gezellige collega’s. De vrijdagmiddag borrel en
                    bedrijfsuitjes mogen daarbij zeker niet ontbreken. We vinden openheid en eerlijkheid belangrijk, dus
                    als er iets is bespreken we dit en lossen we het in overleg op.
                </p>

                <h2>Is je interesse gewekt en wil je meer weten?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(AccountManagerZonnepanelen);
