import React from "react";
import Helmet from "react-helmet";

function MagazijnMedewerker() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Geordende Magazijnmedewerker - Vacature - Werken bij Smith</title>
                <meta name="description" content="In deze functie ben jij de baas in het magazijn! Je gaat lekker zelfstandig aan het werk voor 40 uur per week. Zoek jij dus een baan vol afwisseling waar je lekker praktisch bezig bent dan hoef je niet verder te zoeken. Lees snel verder!" />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Geordende Magazijnmedewerker</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <p>
                    In deze functie ben jij de baas in het magazijn! Je gaat lekker zelfstandig aan het werk voor 40 uur
                    per week. Zoek jij dus een baan vol afwisseling waar je lekker praktisch bezig bent dan hoef je niet
                    verder te zoeken. Lees snel verder!
                </p>

                <h2>Wat ga je doen?</h2>
                <p>
                    Het bedrijf waar je komt te werken is een jong en snelgroeiend bedrijf dat is gespecialiseerd in het
                    verkopen en plaatsen van zonnepanelen in de regio Westland.
                </p>
                <p>
                    Als fulltime magazijnmedewerker ben jij verantwoordelijk voor het laden/lossen van de bussen, orders
                    picken en het netjes houden van het magazijn. Deze orders zijn niet alleen zonnepanelen, maar ook
                    kleinere spullen zoals: omvormers, dakhaken en schroeven. Alles wat nodig is om zonnepanelen te
                    installeren ligt in dit magazijn en als vaste kracht weet jij straks ieder product te liggen.
                </p>
                <p>
                    Je werkt veel zelfstandig, maar staat er niet helemaal alleen voor. Jouw directe collega ondersteund
                    jou in je werkzaamheden, maar hij verzorgt ook het administratieve deel.
                </p>

                <h2>Wat wij vragen</h2>
                <ul>
                    <li>Minimaal mbo4 werk- en denkniveau;</li>
                    <li>Heb je al werkervaring in een magazijn? Dan heb je een streepje voor!</li>
                    <li>Je kunt goed zelfstandig werken en bent gestructureerd;</li>
                    <li>Je beheerst de Nederlandse taal goed en het liefste ook een beetje Westlands.</li>
                </ul>

                <h2>Wat wij bieden</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf,
                    een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland.
                </p>
                <p>
                    Tegelijkertijd vind je alle gekheid hier alleen op stokjes en wordt er keihard gewerkt. Onze
                    organisatie groeit snel en we bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Is je interesse gewekt en wil je meer weten?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(MagazijnMedewerker);
