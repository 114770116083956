import React from "react";
import Helmet from "react-helmet";

function WerkvoorbereiderElektromonteur() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Werkvoorbereider / Elektromonteur die door wil groeien - Vacature - Werken bij Smith</title>
                <meta name="description" content="Als werkvoorbereider organiseer je alles voor je collega’s op de werkvloer ter voorbereiding van alle installatietechnische projecten: werktekeningen, uittrekstaten, materiaal en materieel etc. Zowel voor tuinbouwtechnische projecten als de zonnepanelen. Monteurs worden blij van je werkinstructies en weten je te vinden bij een technische vraag. Daarnaast zit je bovenop deadlines en budgetten." />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Werkvoorbereider / Elektromonteur die door wil groeien</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Tuinbouwtechniek B.V.
                        </b>
                    </div>
                </div>

                <h2>Regelneef <small className="text-muted"><i>(of nicht)</i></small></h2>

                <p>
                    Als werkvoorbereider organiseer je alles voor je collega’s op de werkvloer ter voorbereiding van alle installatietechnische projecten: werktekeningen, uittrekstaten, materiaal en materieel etc. Zowel voor tuinbouwtechnische projecten als de zonnepanelen. Monteurs worden blij van je werkinstructies en weten je te vinden bij een technische vraag. Daarnaast zit je bovenop deadlines en budgetten.
                </p>
                <p>
                    Je bent de ideale stand-in voor onze directeur die normaliter de rol van projectleider heeft en je onderhoudt contacten met de opdrachtgever en leveranciers. Je gaat regelmatig op locatie een klus inspecteren ter voorbereiding en, bij grotere klussen, om de voortgang te bewaken. We hebben grote opdrachten zowel voor Smith Tuinbouwtechniek als Smith Solar. Maar je draait ook je hand niet om voor kleinere klussen, zowel zakelijk als particulier.
                </p>
                <p>
                    Eventuele storingen worden door collega’s verzamelt in ons dashboard. Je vindt het heerlijk om je vast te bijten in storingen. Jij bepaalt of het nodig is om zelf te gaan kijken wat er aan de hand is of dat je de storingsmonteurs erop af kan sturen.
                </p>

                <h2>Wat je in huis moet hebben</h2>

                <ul>
                    <li>
                        Een mbo-opleiding, richting Elektro.
                    </li>
                    <li>
                        Ervaring in een elektrotechnische omgeving.
                    </li>
                    <li>
                        Ervaring met Autocad is een pre.
                    </li>
                    <li>
                        Goede communicatieve vaardigheden en praktische instelling.
                    </li>
                    <li>
                        Bereidheid tot het volgen van met de functie verband houdende cursussen.
                    </li>
                    <li>
                        Geen 9 tot 5 mentaliteit.
                    </li>
                </ul>

                <h2>Knallen</h2>
                <p>
                    Dat we samen vooruit willen bij Smith moge duidelijk zijn. Ooit zijn we gestart met alleen Smith Tuinbouwtechniek B.V., maar inmiddels is Smith Solar B.V. ons paradepaardje.
                </p>
                <p>
                    Onze medewerkers zijn gewend om soms een blauwe en soms een oranje pet op te hebben. Onze organisatie bestaat uit een klein en hecht team, waarbij collegialiteit vanzelfsprekend is en we elke dag keihard knallen voor onze klanten.
                </p>
                <p>
                    Momenteel ligt het zwaartepunt van onze werkzaamheden bij Smith Solar B.V. Als Werkvoorbereider bij Smith hou je je dan ook voornamelijk bezig met het tekenen en voorbereiden van de werkzaamheden van het werk voor je collega’s die het dak op gaan met zonnepanelen.
                </p>

                <h2>Tof bedrijf</h2>
                <p>
                    Momenteel ligt het zwaartepunt van onze werkzaamheden bij Smith Solar B.V. Als Werkvoorbereider bij Smith hou je je dan ook voornamelijk bezig met het tekenen en voorbereiden van de werkzaamheden van het werk voor je collega’s die het dak op gaan met zonnepanelen.
                </p>

                <h2>Satisfaction</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf, een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland. Tegelijkertijd vind je alle gekheid hier alleen op stokjes en wordt er keihard gewerkt.
                </p>
                <p>
                    Onze organisatie groeit snel en we bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(WerkvoorbereiderElektromonteur);
