import React from "react";
import Helmet from "react-helmet";

function WerkvoorbereiderPlanner() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Werkvoorbereider / Planner - Vacature - Werken bij Smith</title>
                <meta name="description" content="Ben jij gestructureerd en hou je van een puzzel als het gaat om het uitzoeken van materialen en voorraden beheren? Ga dan aan de slag binnen deze veelzijdige rol als Werkvoorbereider / Planner bij Smith Solar B.V." />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Werkvoorbereider / Planner</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    Ben jij gestructureerd en hou je van een puzzel als het gaat om het uitzoeken van materialen en voorraden beheren? Ga dan aan de slag binnen deze veelzijdige rol als Werkvoorbereider/ Planner bij Smith Solar B.V.
                </p>

                <h2>Wie ben jij</h2>

                <ul>
                    <li>
                        Als werkvoorbereider/ planner organiseer je alles voor je collega’s op de werkvloer ter voorbereiding van alle installatie technische projecten zoals het plaatsen van zonnepanelen.
                    </li>
                    <li>
                        Jij regelt de monteursplanning, de logistiek en dat deze goed op elkaar zijn afgestemd.
                    </li>
                    <li>
                        Naast het op orde houden van de voorraad administratie ben je ook bezig met het oplossen van vraagstukken.
                    </li>
                    <li>
                        Jij zoekt uit welke onderdelen nodig zijn zodat er voor iedere klant een goede oplossing geboden kan worden.
                    </li>
                    <li>
                        Binnen deze veelzijdige rol ben je allround beschikbaar om de monteurs te ondersteunen waar nodig.
                    </li>
                </ul>

                <h2>Wat je in huis moet hebben</h2>

                <ul>
                    <li>
                        Minimaal mbo werk- en denkniveau
                    </li>
                    <li>
                        Ervaring in de elektrotechniek is een pré
                    </li>
                    <li>
                        Administratieve ervaring is een pré
                    </li>
                    <li>
                        Goede communicatieve vaardigheden en praktische instelling
                    </li>
                    <li>
                        Je bent secuur, stressbestendig en assertief
                    </li>
                </ul>

                <h2>Wie zijn wij</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf, een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland.
                    Tegelijkertijd vind je alle gekheid hier alleen op stokjes en wordt er keihard gewerkt.
                    Onze organisatie bestaat uit een klein en hecht team, waarbij collegialiteit vanzelfsprekend is en we elke dag keihard knallen voor onze klanten. We groeien snel en bieden je uitstekende mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(WerkvoorbereiderPlanner);
