import React from "react";
import Helmet from "react-helmet";

function AdministratiefMedewerkerInkoop() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Administratief medewerker inkoop - Vacature - Werken bij Smith</title>
                <meta name="description" content="Ben jij gestructureerd en hou je van een puzzel als het gaat om uitzoeken van materialen en voorraden beheren? Ga dan aan de slag als administratief medewerker inkoop bij Smith Solar B.V." />
            </Helmet>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Vacature</h1>
                    <p>Administratief medewerker inkoop</p>
                </div>
            </div>

            <div className="container">

                <div className="card mb-3">
                    <div className="card-body text-center">
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-clock"/> Werkweek van 32-40 uur
                        </b>
                        <b className="text-tuinbouw ml-2 mr-2">
                            <i className="fas fa-graduation-cap"/> MBO
                        </b>
                        <b className="text-solar ml-2 mr-2">
                            <i className="fas fa-building"/> Smith Solar B.V.
                        </b>
                    </div>
                </div>

                <p>
                    <i>
                        Ben jij gestructureerd en hou je van een puzzel als het gaat om uitzoeken van materialen en
                        voorraden beheren? Ga dan aan de slag als administratief medewerker inkoop bij Smith Solar B.V.
                    </i>
                </p>

                <h2>Wie bij jij</h2>
                <p>
                    Als administratief medewerker inkoop ondersteun jij de verkopers met alle financiële administratie,
                    zorg je ervoor dat ten allen tijden de administratie voorraadbeheer op orde is. Als er een probleem
                    is, zoek jij dit tot op de bodem uit.
                </p>
                <p>
                    Jij zorgt ervoor dat de bestellingen naar de leveranciers volledig en secuur vastgelegd worden. Door
                    jou is onze voorraad altijd bij!
                </p>
                <p>
                    Naast het op orde houden van de voorraad administratie ben je ook bezig met het oplossen van
                    vraagstukken. Jij zoekt uit welke onderdelen nodig zijn zodat er voor iedere klant een goede
                    oplossing geboden kan worden.
                </p>

                <h2>Wat je in huis moet hebben</h2>
                <ul>
                    <li>
                        Minimaal mbo werk- en denkniveau
                    </li>
                    <li>
                        Administratieve ervaring is een pré
                    </li>
                    <li>
                        Je bent secuur, stressbestendig en assertief
                    </li>
                    <li>
                        Je bent communicatief sterk
                    </li>
                </ul>

                <h2>Satisfaction</h2>
                <p>
                    Als je werkt is het wel zo prettig dat je het er naar je zin hebt. Bij Smith gaat dit als vanzelf,
                    een hipsterbar is er niks bij en dat gewoon in het nuchtere Westland. Tegelijkertijd vind je alle
                    gekheid hier alleen op stokjes en wordt er keihard gewerkt.
                </p>
                <p>
                    Onze organisatie bestaat uit een klein en hecht team, waarbij collegialiteit vanzelfsprekend is en
                    we elke dag keihard knallen voor onze klanten. We groeien snel en bieden je uitstekende
                    mogelijkheden om je talenten te ontplooien.
                </p>

                <h2>Ben jij degene die we zoeken?</h2>
                <p>
                    Stuur dan je motivatie met CV naar Karel Smith
                    via <a href="mailto:hr@smithtechniek.nl">hr@smithtechniek.nl</a> of
                    bel <a href="dial:+31620967847">06 209 678 47</a>.
                </p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(AdministratiefMedewerkerInkoop);
